/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@meterup/atto';
import type { AxiosAPIError, QuoteRequest, QuoteRequestCreateRequest } from '@meterup/connect-api';
import { post } from '@meterup/connect-api';
import { post as newPost } from '@meterup/connect-api/src/axios';
import usePlacesServices from '@meterup/connect-ui/src/components/hooks/usePlacesServices';
import { logError } from '@meterup/connect-ui/src/Log.utils';
import { useMutation } from '@tanstack/react-query';
import cx from 'classnames';
import { Form, FormSpy } from 'react-final-form';
import { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';

import useCompanySlug from '../../dashboard/hooks/useCompanySlug';
// Api
// Components
import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
// Hooks
import useLogEvent, { REQUEST_QUOTE_FORM_SUBMIT_LOCATION } from '../../hooks/useLogEvent';
// Route/APi
import { route } from '../../routes';
// Utils
import { getAddressObjectFromAddressComponents } from '../../utils/Location.utils';
import Search from './Search';
// import { post as newPost } from "../../utils/axios";

// TODO: Type
function LocationForm() {
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { getServicesPromise } = usePlacesServices();

  // Router
  const navigate = useNavigate();

  // APi: Object
  const toQuoteRequestCreateRequestObject = (
    quoteRequest: QuoteRequestCreateRequest,
    companyName: string = '',
  ) => ({
    companyName,
    contactName: '',
    contactEmail: '',
    contactTelephone: '',
    requestedDownloadKbps: Number(quoteRequest?.requestedDownloadKbps),
    requestedUploadKbps: Number(quoteRequest?.requestedUploadKbps),
    contractMinimumMonths: Number(quoteRequest.contractMinimumMonths),
    notes: quoteRequest.notes || '',
    location: quoteRequest?.location,
    contractMaximumInstallDate: quoteRequest.contractMaximumInstallDate,
    existingContractEndDate: quoteRequest.existingContractEndDate,
    existingMonthlyFeeCents: quoteRequest.existingMonthlyFeeCents
      ? Math.round(Number(quoteRequest.existingMonthlyFeeCents) * 100)
      : 0,
    existingProviderSid: quoteRequest.existingProviderSid
      ? String(quoteRequest.existingProviderSid)
      : '',
  });

  // Event logger:
  const logEvent = useLogEvent();

  // Form: events and locally saved values
  const [onChange, temporaryFormValues, setTemporaryFormValues] =
    useFormStorage(REQUEST_QUOTE_FORM_KEY);

  useEffect(() => {
    getServicesPromise().then(() => setIsLoaded(true));
  }, [getServicesPromise]);

  const companySlug = useCompanySlug();
  const createQuoteMutation = useMutation<QuoteRequest, AxiosAPIError, QuoteRequestCreateRequest>(
    (vars) => newPost(`v1/companies/${companySlug}/connect/quote-requests`, vars),
  );
  // Form: Submit
  const onSubmit = useCallback(
    async (values: QuoteRequestCreateRequest) => {
      try {
        let response;
        if (companySlug) {
          const mutation = createQuoteMutation.mutateAsync(
            toQuoteRequestCreateRequestObject(values, companySlug),
          );
          setLoading(true);
          response = await mutation;
        } else {
          response = await post<QuoteRequest, QuoteRequestCreateRequest>(
            'quote-requests',
            toQuoteRequestCreateRequestObject(values),
            setLoading,
          );
        }

        if (response) {
          navigate(route('contract', { sid: response.sid }), { replace: true });
        }
      } catch (e) {
        // Only display error messages for known error IDs from the API
        type errMap = Record<string, boolean>;
        const mappedErrors: errMap = {
          invalid_address: true,
          invalid_email: true,
        };
        // @ts-ignore
        if (mappedErrors[e.id]) {
          // @ts-ignore
          logError(e.message);
        }
      }
      setLoading(false);
      logEvent(REQUEST_QUOTE_FORM_SUBMIT_LOCATION);
    },
    [temporaryFormValues, navigate],
  );

  // Form: Validate
  const validateForm = useCallback(
    async (values: any) => {
      const validations: any = {};
      if (
        !values.location ||
        !values.location.address1 ||
        !values.location.city ||
        !values.location.state ||
        !values.location.postalCode ||
        !values.address
      ) {
        validations.address = 'Please input an address';
      }
      return validations;
    },
    [temporaryFormValues],
  );

  // when coming from https://www.meter.com/connect, the location is passed as a
  // URL parameter, if that is the case, we need to geocodeByAddress the initial
  // address to set the location object.
  // However, to avoid a bug where geocodeByAddress is fired on every address
  // change, we keep track of addressChanged, when set to true (i.e: when user
  // types in the input), geocodeByAddress won't interfere
  const [addressChanged, setAddressChanged] = useState(false);

  // const isLoaded = window?.google?.maps;
  // User type in address input, sets the address and reset the location
  const handleChange = (address: any) => {
    setTemporaryFormValues({
      ...temporaryFormValues,
      address,
    });
    setAddressChanged(true);
  };

  // User pick a suggestion from dropdown, sets the location and reset
  const handleSelect = useCallback((address: string, placeID: string) => {
    if (placeID) {
      geocodeByPlaceId(placeID).then((results) => {
        const first = results[0];
        if (first) {
          const location = {
            latitude: first.geometry.location.lat(),
            longitude: first.geometry.location.lng(),
            ...getAddressObjectFromAddressComponents(first.address_components),
            address2: temporaryFormValues?.address2 || '',
          };

          setTemporaryFormValues({
            ...temporaryFormValues,
            address,
            location,
          });
        }
      });
    }
  }, []);

  // When address is present, but location not present, it means the user came from
  // the main homepage, in which case we need to parse the address ourselves
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const addressParam = params.get('address');
    if (addressParam && isLoaded) {
      geocodeByAddress(addressParam).then((results) => {
        const first = results[0];
        if (first) {
          const location = {
            latitude: first.geometry.location.lat(),
            longitude: first.geometry.location.lng(),
            ...getAddressObjectFromAddressComponents(first.address_components),
          };
          const formValues = {
            ...temporaryFormValues,
            address: addressParam,
            location,
          };

          setTemporaryFormValues(formValues);
          onSubmit(formValues);
        }
      });
    }
  }, [isLoaded, addressChanged]);

  const buttonWrapperClass = cx(
    'bg-white',
    'w-full fixed z-40 left-0 bottom-0 md:relative',
    'border-t border-gray-100 md:border-none',
    'p-4 pt-5 md:p-0 md:pt-0 md:pr-0 md:mt-4',
  );
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={temporaryFormValues}
      validate={validateForm}
      render={({ handleSubmit, submitting, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} />
          <div className="mt-2">
            {isLoaded && (
              <Search
                handleChange={handleChange}
                handleSelect={handleSelect}
                address={temporaryFormValues.address}
              />
            )}
          </div>
          <div className={buttonWrapperClass}>
            <Button
              disabled={submitting || !valid}
              loading={submitting || isLoading}
              size="large"
              width="100%"
              type="submit"
              icon="arrow-right"
              arrangement="leading-label"
            >
              Get free quotes
            </Button>
          </div>
        </form>
      )}
    />
  );
}

export default LocationForm;
