import '@meterup/connect-ui/src/css/tailwind.css';
import '@meterup/atto/src/styles/fonts.css';

import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './App';

if (import.meta.env.REALM === 'dev') {
  const tagManagerArgs = {
    gtmId: 'GTM-NGG8CC7W',
  };

  TagManager.initialize(tagManagerArgs);
}

if (import.meta.env.REALM === 'prod') {
  const tagManagerArgs = {
    gtmId: 'GTM-PK38LLR',
  };

  TagManager.initialize(tagManagerArgs);

  Sentry.init({
    dsn: 'https://9dbca3d31eef4196bc91564a166b3865@o322827.ingest.sentry.io/5730306',
    enabled: import.meta.env.REALM === 'prod',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 0.5,
  });
}

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
