/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Alert, Button, Link, Select, SelectItem, Subheading } from '@meterup/atto';
import {
  put,
  Quote,
  QuoteRequest,
  QuoteRequestUpdateRequest,
  useApiResource,
} from '@meterup/connect-api';
import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import BaseLayout from '../../components/Layout/BaseLayout';
import ManualQuotesList from '../../components/Quotes/ManualQuotesList/ManualQuotesList';
import QuotesList from '../../components/Quotes/QuotesList/QuotesList';
import InstantQuotesResultsEmpty from '../../components/Shared/InstantQuotesResultsEmpty/InstantQuotesResultsEmpty';
import ProvidersProgress from '../../components/Shared/ProvidersProgress/ProvidersProgress';
import QuotesResultsEmpty from '../../components/Shared/QuotesResultsEmpty/QuotesResultsEmpty';
import QuotesResultsFilters from '../../components/Shared/QuotesResultsFilters/QuotesResultsFilters';
import QuotesResultsSidebar from '../../components/Shared/QuotesResultsSidebar/QuotesResultsSidebar';
import ShareQuotes from '../../components/Shared/ShareQuotes/ShareQuotes';
import useConnectionTypeFilter from '../../hooks/useConnectionTypeFilter';
import useContractLengthMonthsFilter from '../../hooks/useContractLengthMonthsFilter';
import useDownloadMbpsFilter from '../../hooks/useDownloadMbpsFilter';
import useMonthlyFeeFilter from '../../hooks/useMonthlyFeeFilter';
import useProvidersFilter from '../../hooks/useProvidersFilter';
// Hooks
import useQuotes from '../../hooks/useQuotes';
import { route } from '../../routes';
import { formatBandwidth } from '../../utils/formatBandwidth';

enum SortStrategy {
  Price = 'price',
  Bandwidth = 'bandwidth',
}

const SORT_OPTIONS = [
  { label: 'Sort by: price', value: SortStrategy.Price },
  { label: 'Sort by: bandwidth', value: SortStrategy.Bandwidth },
];

export default function QuoteRequestsResults() {
  // Router
  const navigate = useNavigate();

  // QuoteRequest id
  const { sid } = useParams<{ sid: string }>();

  // Get quoteRequest
  const [loading, quoteRequest, reloadQuoteRequest] = useApiResource<QuoteRequest>(
    `quote-requests/${sid}`,
    false,
    true,
  );

  // Selected quotes SIDs
  const selectedQuoteSids = useMemo(
    () => (quoteRequest ? quoteRequest.selectedQuotes : []),
    [quoteRequest],
  );

  // Quotes
  const {
    reloadQuotes,
    enabledQuotes,
    proposedQuotes,
    generatedQuotes,
    selectedQuotes,
    unselectedQuotes,
    primaryQuote,
    secondaryQuote,
    recommendedQuotes,
    // @ts-ignore
  } = useQuotes(sid, selectedQuoteSids);

  // States - which quote is being updated
  const [quoteLoadingSid, setQuoteLoadingSid] = useState('');

  // States - Provider search progress bar
  const [progressPercentage, setProgressPercentage] = useState(10);

  // States - Part of the UI is blurred until we have quotes
  const [readyForReview, setReadyForReview] = useState(false);

  const [showMobileFilters, setShowMobileFilters] = useState(false);

  // States - Whether user is sorting by price or bandwidth
  const [sortStrategy, setSortStrategy] = useState(SortStrategy.Price);

  // We only show filters in the left sidebar if there's more than one quote
  const [showFilters, setShowFilters] = useState<boolean>(generatedQuotes.length > 1);

  useEffect(() => {
    setShowFilters(generatedQuotes.length > 1);
  }, [enabledQuotes]);

  // Monthly fee filter
  const {
    minMonthlyFee,
    maxMonthlyFee,
    currentMonthlyFee,
    onMonthlyPriceChange,
    resetMonthlyPriceFilter,
  } = useMonthlyFeeFilter(generatedQuotes);

  // DownloadMbps filter
  const {
    quotesByDownloadMbps,
    allDownloadMbps,
    currentDownloadMbps,
    onDownloadMbpsChange,
    resetDownloadMbpsFilter,
  } = useDownloadMbpsFilter(generatedQuotes, quoteRequest);

  // Contract length filter
  const {
    quotesByContractLengthMonths,
    allContractLengthMonths,
    currentContractLengthMonths,
    onContractLengthMonthsChange,
    resetContractLengthMonthsFilter,
  } = useContractLengthMonthsFilter(generatedQuotes);

  const {
    allConnectionTypes,
    currentConnectionTypes,
    onCurrentConnectionTypesChange,
    quotesByConnectionType,
    resetCurrentConnectionTypes,
  } = useConnectionTypeFilter(generatedQuotes);

  const {
    quotesByProvider,
    allProviders,
    currentProviders,
    onProvidersChange,
    resetProvidersFilter,
  } = useProvidersFilter(generatedQuotes);

  const resetFilters = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    resetMonthlyPriceFilter();
    resetDownloadMbpsFilter();
    resetContractLengthMonthsFilter();
    resetProvidersFilter();
    resetCurrentConnectionTypes();
  };

  // quotes filtered by above filters
  const filteredQuotes = useMemo(
    () =>
      unselectedQuotes
        .filter((quote: Quote) => quote.monthlyFeeCents / 100 <= currentMonthlyFee)
        .filter((quote: Quote) => currentContractLengthMonths.includes(quote.contractLengthMonths))
        .filter((quote: Quote) => currentDownloadMbps.includes(formatBandwidth(quote.downloadKbps)))
        .filter((quote: Quote) =>
          currentProviders.includes(quote.provider ? quote.provider.name : ''),
        )
        .filter((quote: Quote) =>
          currentConnectionTypes.includes(quote.connectionType?.name || 'unknown'),
        )
        .sort((a, b) => (a.monthlyFeeCents < b.monthlyFeeCents ? -1 : 1)),
    [
      unselectedQuotes,
      currentMonthlyFee,
      currentContractLengthMonths,
      currentDownloadMbps,
      currentProviders,
      currentConnectionTypes,
    ],
  );

  // User select an automated quote
  const onSelectQuote = useCallback(
    (quoteSid: string) => {
      // Can only select 2 quotes max
      if (selectedQuoteSids.length > 1) {
        return;
      }

      // Set loading state for the quote
      setQuoteLoadingSid(quoteSid);

      const data = {
        selectedQuotes: [...selectedQuoteSids, quoteSid],
        selectedQuotesChanged: true,
      };

      // Update
      put<QuoteRequestUpdateRequest, QuoteRequest>(`quote-requests/${sid}`, data).then(
        // @ts-ignore
        (response) => {
          // Go to confirm page
          navigate(route('confirm', { sid }));
        },
      );
    },
    [selectedQuoteSids],
  );

  const onDeselectQuote = useCallback(
    (quoteSid: string) => {
      setQuoteLoadingSid(quoteSid);

      // Selected quotes Ids
      const selectedQuotes = [
        ...selectedQuoteSids.slice(0, selectedQuoteSids.indexOf(quoteSid)),
        ...selectedQuoteSids.slice(selectedQuoteSids.indexOf(quoteSid) + 1),
      ];

      // Data
      const data = { selectedQuotes, selectedQuotesChanged: true };

      // Request
      put<QuoteRequestUpdateRequest, QuoteRequest>(`quote-requests/${sid}`, data).then(
        (response) => {
          reloadQuoteRequest &&
            reloadQuoteRequest().then((response) => {
              setQuoteLoadingSid('');
              reloadQuotes && reloadQuotes();
            });
        },
      );
    },
    [selectedQuoteSids],
  );

  const onPinQuote = useCallback((quoteSid: string) => {
    // setQuoteLoadingSid(quoteSid);
    const data = {
      recommended: true,
      recommendedChanged: true,
    };

    put(`quote-requests/${sid}/quotes/${quoteSid}`, data).then((response) => {
      reloadQuotes && reloadQuotes();
    });
  }, []);

  const onUnpinQuote = useCallback((quoteSid: string) => {
    // setQuoteLoadingSid(quoteSid);
    const data = {
      recommended: false,
      recommendedChanged: true,
    };

    put(`quote-requests/${sid}/quotes/${quoteSid}`, data).then((response) => {
      reloadQuotes && reloadQuotes();
    });
  }, []);

  // Request a manual quote
  const onRequestManualQuote = useCallback(
    (quoteSid: string) => {
      setQuoteLoadingSid(quoteSid);
      put(`quote-requests/${sid}/quotes/${quoteSid}`, { status: 'requested' }).then((response) => {
        reloadQuotes &&
          reloadQuotes().then((response) => {
            setQuoteLoadingSid('');
          });
      });
    },
    [selectedQuoteSids],
  );

  // Click next
  const onConfirmClick = useCallback(() => {
    if (sid) {
      navigate(route('confirm', { sid }));
    }
  }, [sid]);

  // Classes
  const sectionClasses = cx('transition-all duration-150', {
    'filter blur-sm': !readyForReview,
    'filter blur-0': !readyForReview,
  });

  // Markup
  const address = quoteRequest?.location?.address?.address1
    ? quoteRequest?.location?.address?.address1
    : '';

  // On render/QuoteRequest loaded
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (quoteRequest && quoteRequest.status == 'ready_for_review') {
      setReadyForReview(true);
    } else if (!loading && quoteRequest && quoteRequest.status == 'contract_requested') {
      navigate(route('completed', { sid }));

      // The API can take up to 2 minutes to return automated quotes
      // this "fake" progressing every 1.3 seconds
    } else {
      timer = setTimeout(() => {
        setProgressPercentage(progressPercentage + Math.random() * 2);
        reloadQuoteRequest && reloadQuoteRequest();
        reloadQuotes && reloadQuotes();
      }, 1300);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [loading, quoteRequest, progressPercentage]);

  if (loading) {
    return null;
  }

  const renderMainContent = () => {
    let emptyView;
    if (enabledQuotes.length == 0) {
      emptyView = <QuotesResultsEmpty address={address} />;
    } else if (generatedQuotes.length == 0) {
      // @ts-ignore
      emptyView = <InstantQuotesResultsEmpty address={address} />;
    }

    const totalQuotes = generatedQuotes.length;
    const visibleQuotes = selectedQuotes.length + filteredQuotes.length;

    return (
      <div className="md:mt-0">
        {emptyView}
        {generatedQuotes.length > 0 && (
          <>
            <div className="mb-0 px-4 flex justify-between items-center border-b border-gray-100 min-h-48 max-h-48">
              {visibleQuotes == totalQuotes && <Subheading>Quotes</Subheading>}
              {visibleQuotes != totalQuotes && (
                <Subheading>
                  {visibleQuotes} out of{' '}
                  <Link href="#" onClick={resetFilters}>
                    {totalQuotes} quotes
                  </Link>
                </Subheading>
              )}
              <div className="inline-block">
                <Select
                  value={sortStrategy}
                  onValueChange={(v) => setSortStrategy(v as SortStrategy)}
                >
                  {SORT_OPTIONS.map((option) => (
                    <SelectItem key={option.value}>{option.label}</SelectItem>
                  ))}
                </Select>
              </div>
            </div>
            <div>
              <div className={filterButtonWrapperClass}>
                <div className="py-4">
                  <Button
                    onClick={() => setShowMobileFilters(true)}
                    arrangement="leading-icon"
                    icon="filter"
                    width="100%"
                    size="large"
                    variant="secondary"
                  >
                    Filters
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-1">
              <QuotesList
                quotes={filteredQuotes}
                // @ts-ignore
                primaryQuote={primaryQuote}
                secondaryQuote={secondaryQuote}
                selectedQuotes={selectedQuotes}
                recommendedQuotes={recommendedQuotes}
                selectedQuoteSids={selectedQuoteSids}
                shouldGroupByBandwidth={sortStrategy === SortStrategy.Bandwidth}
                onSelectQuote={onSelectQuote}
                onDeselectQuote={onDeselectQuote}
                onPinQuote={onPinQuote}
                onUnpinQuote={onUnpinQuote}
                quoteLoadingSid={quoteLoadingSid}
              />
            </div>
          </>
        )}
        {proposedQuotes.length > 0 && (
          <div className="mt-8 mb-12">
            <ManualQuotesList
              quotes={proposedQuotes}
              onRequestManualQuote={onRequestManualQuote}
              quoteLoadingSid={quoteLoadingSid}
            />
          </div>
        )}
      </div>
    );
  };

  const filterWrapperClass = cx(
    sectionClasses,
    'w-full pt-0 pb-12 px-4 mt-0',
    'md:inline-block md:max-w-xs md:col-span-3 md border-r border-gray-100',
    'md:h-screen md:overflow-y-auto md:sticky md:top-0',
    'md:pt-0',
    {
      hidden: !showMobileFilters,
      'absolute inset-0 bg-white z-200 top-16 bottom-18 overflow-auto	overflow-x-hidden':
        showMobileFilters,
    },
  );

  const contentWrapperClass = cx(
    'w-full pt-0 pb-0',
    'md:border-r md: pt-0 border-gray-100 px-0 lg:px-6',
    {
      hidden: showMobileFilters,
    },
  );

  const sidebarWrapperClass = cx(
    sectionClasses,
    'w-full md:max-w-xs pb-28 border-t border-gray-100 bg-gray-50',
    'lg:border-none lg:bg-white',
    'md:h-screen md:overflow-y-auto md:sticky md:top-0 md:border-none md:bg-white',
    {
      hidden: showMobileFilters,
    },
  );

  const filterButtonWrapperClass = cx('mt-12 md:hidden w-full px-4', {
    hidden: showMobileFilters,
  });

  const filteredButtonWrapperClass = cx(
    'bg-white',
    'w-full fixed z-200 left-0 bottom-0 md:relative',
    'border-t border-gray-100 md:border-none',
    'p-4 py-7 pr-0 md:hidden',
    {
      hidden: !showMobileFilters,
    },
  );

  const buttonWrapperClass = cx(
    'bg-white p-4 pr-0 border-t border-gray-100 2xl:border-none ',
    'w-full fixed left-0 bottom-0 md:relative z-200',
    'border-t border-gray-100 md:border-none',
    {
      hidden: showMobileFilters,
    },
  );

  // TODO: Move filters to context
  return (
    <BaseLayout align="top" layoutMargin={false}>
      <div className="w-full flex flex-col md:flex-row h-full">
        <div className={filterWrapperClass}>
          <QuotesResultsFilters
            quoteRequest={quoteRequest}
            showFilters={showFilters}
            minMonthlyFee={minMonthlyFee}
            maxMonthlyFee={maxMonthlyFee}
            currentMonthlyFee={currentMonthlyFee}
            onMonthlyPriceChange={onMonthlyPriceChange}
            quotesByDownloadMbps={quotesByDownloadMbps}
            allDownloadMbps={allDownloadMbps}
            currentDownloadMbps={currentDownloadMbps}
            onDownloadMbpsChange={onDownloadMbpsChange}
            quotesByContractLengthMonths={quotesByContractLengthMonths}
            allContractLengthMonths={allContractLengthMonths}
            currentContractLengthMonths={currentContractLengthMonths}
            onContractLengthMonthsChange={onContractLengthMonthsChange}
            quotesByProvider={quotesByProvider}
            allProviders={allProviders}
            currentProviders={currentProviders}
            onProvidersChange={onProvidersChange}
            allConnectionTypes={allConnectionTypes}
            currentConnectionTypes={currentConnectionTypes}
            onCurrentConnectionTypesChange={onCurrentConnectionTypesChange}
            quotesByConnectionType={quotesByConnectionType}
          />
          <div className={filteredButtonWrapperClass}>
            <Button onClick={() => setShowMobileFilters(false)} width="100%" size="large">
              Show {filteredQuotes.length} {filteredQuotes.length == 1 ? 'quote' : 'quotes'}
            </Button>
          </div>
        </div>

        <div className={contentWrapperClass}>
          {!readyForReview && <ProvidersProgress progress={progressPercentage} />}
          {readyForReview && renderMainContent()}
        </div>

        <div className={sidebarWrapperClass}>
          <div className="px-4 w-full">
            <ShareQuotes address={address} />
          </div>
          <div className="px-4 w-full">
            <div className="" />
          </div>
          <div className="p-4 w-full">
            <QuotesResultsSidebar />
          </div>
          <div>
            {readyForReview && selectedQuotes.length > 0 && (
              <div className={buttonWrapperClass}>
                <Button
                  onClick={onConfirmClick}
                  size="large"
                  icon="arrow-right"
                  arrangement="leading-label"
                  width="100%"
                  disabled={selectedQuotes.length == 0}
                >
                  View and confirm quotes
                </Button>
                <div className="mt-3">
                  <Alert
                    icon="information"
                    variant="positive"
                    copy="We’ll contact you before anything is committed."
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
