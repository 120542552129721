/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable no-empty */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useCallback, useMemo } from 'react';

import { Body, Button, FieldContainer, Icon, Link } from '@meterup/atto';
// Api
import { Provider, useApiResources } from '@meterup/connect-api';
import { ProviderLogo, Select } from '@meterup/connect-ui';
import cx from 'classnames';
import { Field, Form, FormSpy } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
// Hooks
import useLogEvent, { REQUEST_QUOTE_FORM_SUBMIT_EXISTING_PROVIDER } from '../../hooks/useLogEvent';
import useRouter from '../../hooks/useRouter';
import { route } from '../../routes';

const TOP_PROVIDERS_NAME = ['AT&T', 'Comcast', 'Verizon'];

function TopProviderForm() {
  // Router
  const navigate = useNavigate();

  const { sid } = useParams<{ sid: string }>();

  // Event logger:
  const logEvent = useLogEvent();

  const { handleOnClick } = useRouter();

  // Form: events and locally saved values
  const [_onChange, temporaryFormValues, setTemporaryFormValues] =
    useFormStorage(REQUEST_QUOTE_FORM_KEY);

  // Form: Submit
  const handleSubmit = useCallback(async () => {
    // do nothing
  }, [navigate]);

  // Form: Validate
  const validateForm = useCallback(
    async (values: any) => {
      const validations: any = {};
      if (!values.existingProviderSid) {
        validations.existingProviderSid = 'Please enter a provider';
      }

      return validations;
    },
    [temporaryFormValues],
  );

  const [providersLoading, providers] = useApiResources('providers', 'providers');

  const topProviders = useMemo(
    () => providers.filter(({ name }) => TOP_PROVIDERS_NAME.includes(name)),
    [providers],
  );

  const providerOptions = useMemo(
    () =>
      providers
        .filter(({ name }) => !TOP_PROVIDERS_NAME.includes(name))
        .map((provider: Provider) => ({
          label: provider.name,
          value: provider.sid,
        })),
    [providers],
  );

  const onClickSkip = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      navigate(route('submit', { sid }));
    },
    [navigate],
  );

  const buttonWrapperClass = cx(
    'bg-white',
    'w-full fixed z-200 left-0 bottom-0 md:relative',
    'border-t border-gray-100 md:border-none',
    'p-4 pt-5 md:p-0 md:pt-8 pr-0 md:pr-0 md:mt-0',
  );

  const providerButtonClasses = cx(
    'w-full mt-3 px-4 py-3 rounded-md border border-gray-100 hover:border-gray-200',
    'hover:bg-gray-50 cursor-pointer m-focus text-left flex items-center',
    'justify-between h-14',
  );

  const setExistingProviderSid = (providerSid: string) => {
    setTemporaryFormValues({
      ...temporaryFormValues,
      existingProviderSid: providerSid,
    });

    logEvent(REQUEST_QUOTE_FORM_SUBMIT_EXISTING_PROVIDER);
    navigate(route('provider', { sid }));
  };

  function ProviderButton(provider: Provider) {
    return (
      <button
        className={providerButtonClasses}
        type="button"
        key={provider.sid}
        onClick={() => setExistingProviderSid(provider.sid)}
      >
        <div className="w-20">
          <ProviderLogo name={provider.name} path={provider.path} />
        </div>
        <div className="pl-2 text-gray-500">
          <Icon icon="chevron-right" color={{ dark: 'gray200', light: 'gray600' }} size={12} />
        </div>
      </button>
    );
  }

  const formOnChange = useCallback(
    ({ values }: { values: any }) => {
      if (values.existingProviderSid != temporaryFormValues.existingProviderSid) {
        setExistingProviderSid(values.existingProviderSid);
      }
    },
    [temporaryFormValues],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={temporaryFormValues}
      validate={validateForm}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={formOnChange} />
          <div className="rounded-md overflow-hidden">
            <FieldContainer>
              <div className="p-4">
                <Body weight="bold">Who is your current internet service provider?</Body>
                {topProviders.map(ProviderButton)}
                <div className="mt-4">
                  <Field
                    render={(props) => (
                      <Select {...props} disabled={providersLoading} options={providerOptions} />
                    )}
                    size="medium"
                    value=""
                    name="existingProviderSid"
                    id="existingProviderSid"
                    placeholder="Other providers"
                  />
                </div>
              </div>
            </FieldContainer>
          </div>
          <div className={buttonWrapperClass}>
            <div className="flex items-center">
              <div className="w-full flex-justify-between">
                <Link onClick={handleOnClick} href={route('contract', { sid })}>
                  <div className="flex items-center text-sm">
                    <span className="text-sm mr-1">
                      <Icon icon="arrow-left" />
                    </span>
                    <span>Back</span>
                  </div>
                </Link>
              </div>
              <div className="w-1/2">
                <Button
                  size="large"
                  type="button"
                  onClick={onClickSkip}
                  width="100%"
                  variant="secondary"
                  icon="arrow-right"
                  arrangement="leading-label"
                >
                  Skip
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

export default TopProviderForm;
