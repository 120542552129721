/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useCallback, useState } from 'react';

import { Alert, Body, Button, FieldContainer, Icon, Label, Link } from '@meterup/atto';
// Api
import {
  put,
  QuoteRequest,
  QuoteRequestCreateRequest,
  QuoteRequestUpdateRequest,
} from '@meterup/connect-api';
import { QuoteRequestUpdateRequest_Status } from '@meterup/connect-api/src/connectProtos';
import { TextInput } from '@meterup/connect-ui';
import { has } from '@meterup/connect-ui/src/utils';
import { required, validEmail } from '@meterup/connect-ui/src/validation';
import cx from 'classnames';
import { isString } from 'lodash-es';
import { Field, Form, FormSpy } from 'react-final-form';
import TagManager from 'react-gtm-module';
// Routes
// Hooks
import { useNavigate, useParams } from 'react-router-dom';

import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
import useLogEvent, {
  REQUEST_QUOTE_FORM_SUBMIT_CONTACT_INFORMATION,
} from '../../hooks/useLogEvent';
import { route } from '../../routes';

const validateEmail = (value: string) => required(value) || validEmail(value);

function BandwidthForm() {
  // TODO: Magic string -> Constant somewhere
  const [onChange, temporaryFormValues, setTemporaryFormValues] =
    useFormStorage(REQUEST_QUOTE_FORM_KEY);
  const navigate = useNavigate();

  // Event logger:
  const logEvent = useLogEvent();

  // APi: Object
  const toQuoteRequestUpdateRequestObject = (quoteRequest: QuoteRequestUpdateRequest) => ({
    companyName: String(quoteRequest.companyName),
    contactName: String(quoteRequest.contactName),
    contactEmail: String(quoteRequest.contactEmail),
    contactTelephone: '',
    requestedDownloadKbps: Number(quoteRequest?.requestedDownloadKbps),
    requestedUploadKbps: Number(quoteRequest?.requestedUploadKbps),
    contractMinimumMonths: Number(quoteRequest.contractMinimumMonths),
    notes: quoteRequest.notes || '',
    contractMaximumInstallDate: quoteRequest.contractMaximumInstallDate
      ? new Date(quoteRequest.contractMaximumInstallDate)
      : null,
    existingMonthlyFeeCents: quoteRequest.existingMonthlyFeeCents
      ? Math.round(Number(quoteRequest.existingMonthlyFeeCents) * 100)
      : 0,
    existingProviderSid: quoteRequest.existingProviderSid
      ? String(quoteRequest.existingProviderSid)
      : '',
  });

  const { sid } = useParams<{ sid: string }>();

  const [, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = useCallback(
    async (values: QuoteRequestCreateRequest) => {
      try {
        logEvent(REQUEST_QUOTE_FORM_SUBMIT_CONTACT_INFORMATION);
        setError('');

        const response = await put<QuoteRequest, QuoteRequestUpdateRequest>(
          `quote-requests/${sid}`,
          // @ts-ignore
          toQuoteRequestUpdateRequestObject({
            ...values,
            status: QuoteRequestUpdateRequest_Status.unknown,
          }),
          setLoading,
        );

        if (response) {
          setTemporaryFormValues({});
          TagManager.dataLayer({
            dataLayer: {
              event: 'conversion',
              send_to: 'AW-475626357/K-YMCNyXoJcYEPX25eIB',
            },
          });
          navigate(route('results', { sid }));
        }
      } catch (e) {
        // Only display error messages for known error IDs from the API
        const mappedErrors: Record<string, boolean> = {
          invalid_address: true,
          invalid_email: true,
        };
        if (has(e, 'id') && has(e, 'message') && isString(e.id) && mappedErrors[e.id]) {
          setError(String(e.message));
        }
        setLoading(false);
      }
    },
    [temporaryFormValues, navigate],
  );

  const buttonWrapperClass = cx(
    'bg-white',
    'w-full fixed z-200 left-0 bottom-0 md:relative',
    'border-t border-gray-100 md:border-none',
    'p-4 pt-5 md:p-0 md:pt-8 pr-0 md:pr-0 md:mt-0',
  );

  const onClickBack = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      navigate(-1);
    },
    [navigate],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={temporaryFormValues}
      render={({ handleSubmit, submitting, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} />
          <FieldContainer>
            <div className="rounded-md overflow-hidden">
              <div className="overflow-hidden hidden">
                <input type="email" />
              </div>
              <div className="p-4">
                <div>
                  <Label htmlFor="contactEmail">Work email</Label>
                  <div className="mt-2">
                    <Field
                      component={TextInput}
                      size="large"
                      name="contactEmail"
                      type="email"
                      id="contactEmail"
                      placeholder="Your work email"
                      validate={validateEmail}
                    />

                    <p className="sm mt-2 flex items-start">
                      <span className="text-xs text-gray-600 mr-1">
                        <Icon
                          icon="secure"
                          color={{ dark: 'gray200', light: 'gray600' }}
                          size={14}
                        />
                      </span>
                      <Body>We will never sell or spam your email</Body>
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <Label htmlFor="contactName">Your name</Label>
                  <div className="mt-2">
                    <Field
                      component={TextInput}
                      size="large"
                      name="contactName"
                      type="text"
                      id="contactName"
                      placeholder="First and last name"
                      validate={required}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <Label htmlFor="companyName">Company</Label>
                  <div className="mt-2">
                    <Field
                      component={TextInput}
                      size="large"
                      name="companyName"
                      type="text"
                      id="companyName"
                      placeholder="Company"
                      validate={required}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FieldContainer>
          {error && (
            <div className="mt-8">
              <Alert heading={error} />
            </div>
          )}
          <div className={buttonWrapperClass}>
            <div className="flex items-center ">
              <div className="w-full justify-between">
                <Link onClick={onClickBack} href="#">
                  <div className="flex items-center text-sm">
                    <span className="text-rsm mr-1" />
                    <span>Back</span>
                  </div>
                </Link>
              </div>
              <div className="w-auto">
                <Button
                  disabled={submitting || !valid}
                  size="large"
                  width="auto"
                  type="submit"
                  loading={submitting}
                  icon="arrow-right"
                  arrangement="leading-label"
                >
                  Generate quotes
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

export default BandwidthForm;
