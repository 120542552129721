/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';

// Api
import { Body, Button, FieldContainer, Link } from '@meterup/atto';
import { TextInput } from '@meterup/connect-ui';
import cx from 'classnames';
import { Field, Form, FormSpy } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
// Hooks
import useLogEvent, {
  REQUEST_QUOTE_FORM_SUBMIT_EXISTING_PROVIDER_DETAILS,
} from '../../hooks/useLogEvent';
import useRouter from '../../hooks/useRouter';
import { route } from '../../routes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
function ProviderForm() {
  // Router
  const navigate = useNavigate();

  const { sid } = useParams<{ sid: string }>();

  // Event logger:
  const logEvent = useLogEvent();

  const { handleOnClick } = useRouter();

  // Form: events and locally saved values
  const [onChange, temporaryFormValues] = useFormStorage(REQUEST_QUOTE_FORM_KEY);

  // Form: Submit
  const handleSubmit = useCallback(async () => {
    // Log event
    logEvent(REQUEST_QUOTE_FORM_SUBMIT_EXISTING_PROVIDER_DETAILS);

    // Redirect to submit
    navigate(route('submit', { sid }));
  }, [navigate]);

  // Form: Validate
  const validateForm = useCallback(
    async (values: any) => {
      const validations: any = {};
      if (!values.existingProviderSid) {
        validations.existingProviderSid = 'Please enter a provider';
      }

      return validations;
    },
    [temporaryFormValues],
  );

  const buttonWrapperClass = cx(
    'bg-white',
    'w-full fixed z-200 left-0 bottom-0 md:relative',
    'border-t border-gray-100 md:border-none',
    'p-4 pt-5 md:p-0 md:pt-8 pr-0 md:pr-0 md:mt-0',
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={temporaryFormValues}
      validate={validateForm}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      render={({ handleSubmit, submitting, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} />
          <div className="rounded-md overflow-hidden">
            <FieldContainer>
              <div className="p-4 mb-nudge-2px flex justify-between items-center">
                <Body weight="bold">When does your contract expire?</Body>
                <div className="w-1/2 pl-2">
                  <Field<string>
                    component={TextInput}
                    name="existingContractEndDate"
                    type="date"
                    size="large"
                    id="existingContractEndDate"
                  />
                </div>
              </div>
              <div className="p-4 flex justify-between items-center">
                <Body weight="bold">What’s your monthly rate?</Body>
                <div className="w-1/2 pl-2">
                  <Field<string>
                    component={TextInput}
                    name="existingMonthlyFeeCents"
                    type="number"
                    size="large"
                    prefix="USD $"
                    id="existingMonthlyFeeCents"
                  />
                </div>
              </div>
            </FieldContainer>
            {/* </AnimateHeight> */}
          </div>

          <div className={buttonWrapperClass}>
            <div className="flex items-center">
              <div className="w-full flex justify-between">
                <Link onClick={handleOnClick} href={route('topProviders', { sid })}>
                  <div className="flex items-center text-sm">
                    {/* <span className="text-sm mr-1">
                          <Icon icon="arrow-left" />
                        </span> */}
                    <span>Back</span>
                  </div>
                </Link>
              </div>
              <div className="w-1/2">
                <Button
                  disabled={submitting || !valid}
                  loading={submitting}
                  size="large"
                  width="100%"
                  type="submit"
                  icon="arrow-right"
                  arrangement="leading-label"
                >
                  Final step
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

export default ProviderForm;
